import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setIsScrolled(scrollPosition > 50); 
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      {/* Nav Bar */}
      <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
        <div className="logo">
          <img src={`${process.env.PUBLIC_URL}/cfc.png`} alt="Logo" />
        </div>
        <ul className="nav-links">
          <li><a href="#about">About</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>

      {/* Hero Section */}
      <section id="hero" className="hero">
        <div className="hero-left">
          <h1>Chartered Financial Consultancy Pvt. Ltd.</h1>
        </div>
        <div className="hero-right">
          <img src={`${process.env.PUBLIC_URL}/investor-trader-discussing-statistic-data-holding-papers-with-financial-charts-pen-cropped-shot-broker-job-trading-concept.jpg`} alt="Hero" />
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="about">
        <h2>About Us</h2>
        <p>
        At Chartered Financial Consultancy Private Limited, we are dedicated to empowering individuals and businesses with expert financial guidance<br/> tailored to their unique needs. Our team of experienced professionals brings a wealth of knowledge in areas such as investment planning,<br/> tax strategy, retirement planning, risk management and foreign direct investment in Nepal. 
        </p>
      </section>

      {/* Our Mission Section */}
      <section id="mission" className="mission">
        <h2>Our Mission</h2>
        <p>
          Our mission is to provide clear, actionable financial advice that helps our clients achieve their goals.<br/>We believe that financial literacy is key to making informed decisions.
        </p>
      </section>

      {/* Services Section */}
      <section id="services" className="services">
        <h2>Our Services</h2>
        <div className="services-container">
          <div className="service-box">
            <h4>Investment Management</h4>
            <p>We develop personalized investment strategies designed to align with your financial goals.</p>
          </div>
          <div className="service-box">
            <h4>Tax Planning</h4>
            <p>Our experts analyze your financial situation to minimize tax liabilities and maximize savings.</p>
          </div>
          <div className="service-box">
            <h4>Retirement Planning</h4>
            <p>We help you create a roadmap for a secure retirement, ensuring you have the resources to enjoy your golden years.</p>
          </div>
          <div className="service-box">
            <h4>Risk Management</h4>
            <p>We assess potential risks to your financial health and recommend strategies to mitigate them.</p>
          </div>
        </div>
      </section>

      {/* Founder Section */}
      <section id="founder" className="founder">
        <h2>Our Founder</h2>
        <p>
          Message from the founder of Chartered Financial Consultancy Pvt. Ltd.
        </p>
      </section>

      {/* Footer Section */}
<footer id="contact" className="footer">
  <div className="footer-content">
    <div className="footer-logo">
      <img src={`${process.env.PUBLIC_URL}/cfc.png`} alt="Logo" />
      <span>Chartered Financial Consultancy Pvt. Ltd.</span>
    </div>
    <div className="footer-contact">
      <h2>Contact Us</h2>
      <p>Email: info@cfc.com.np</p>
      <p>Contact number: +977-015455183</p>
      <p>Address: Lalitpur, Nepal</p>
    </div>
  </div>
</footer>

    </div>
  );
}

export default App;
